<template>
  <div>
    <p v-if="location.address">Address: {{ location.address }}</p>
    <p class ="addressText" v-else>No address available.</p>
    <form @submit.prevent="handleSubmit">
        <h4 class="playerHeading">Big 2 Players</h4>
      <div class="checkbox-group">
        <div class="checkbox-item">
          <input type="checkbox" id="players-daniel" v-model="formData.players" value="Daniel">
          <label for="players-daniel">Daniel</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-jason" v-model="formData.players" value="Jason">
          <label for="players-jason">Jason</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-josh" v-model="formData.players" value="Josh">
          <label for="players-josh">Josh</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-kulin" v-model="formData.players" value="Kulin">
          <label for="players-kulin">Kulin</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-nath" v-model="formData.players" value="Nath">
          <label for="players-nath">Nath</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-vis" v-model="formData.players" value="Vis">
          <label for="players-vis">Vis</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-wong" v-model="formData.players" value="Wong">
          <label for="players-wong">Wong</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-xinzun" v-model="formData.players" value="Xinzun">
          <label for="players-xinzun">Xinzun</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-zheng" v-model="formData.players" value="Zheng">
          <label for="players-zheng">Zheng</label>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-custom" v-model="formData.players" :value="sanitizeInput(customPlayerName)" />
          <input type="text" v-model="customPlayerName" placeholder="Player Name"/>
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-custom2" v-model="formData.players" :value="sanitizeInput(customPlayerName2)" />
          <input type="text" v-model="customPlayerName2" placeholder="Player Name" />
        </div>

        <div class="checkbox-item">
          <input type="checkbox" id="players-custom3" v-model="formData.players" :value="sanitizeInput(customPlayerName3)" />
          <input type="text" v-model="customPlayerName3" placeholder="Player Name" />
        </div>
      </div>

      

      <div class="dropdown-group">
        <div class="select-container">
          <h4>Winner</h4>
          <div>
            <select id="winner-dropdown" v-model="formData.winner">
              <option v-for="player in formData.players" :key="player" :value="player">{{ player }}</option>
            </select>
          </div>
        </div>
        

        <div class="select-container">
          <h4>Second</h4>
          <div>
            <select id="second-dropdown" v-model="formData.second">
              <option v-for="player in formData.players" :key="player" :value="player">{{ player }}</option>
            </select>
          </div>
        </div>
        

        <div class="select-container">
          <h4>Third</h4>
          <div>
            <select id="third-dropdown" v-model="formData.third">
              <option v-for="player in formData.players" :key="player" :value="player">{{ player }}</option>
            </select>
          </div>
        </div>
        

        <div class="select-container">
          <h4>Loser</h4>
          <div>
            <select id="loser-dropdown" v-model="formData.loser">
              <option v-for="player in formData.players" :key="player" :value="player">{{ player }}</option>
            </select>
          </div>
        </div>
      </div>
     
      <button type="submit">Submit</button>
    </form>
  </div>

  <div id="postMessage">
    <p v-if="successMessage">{{ successMessage }}</p>
    <p v-if="validationMessage">{{ validationMessage }}</p> <!-- Display validation message -->
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import axios from 'axios';

// Set Axios defaults for CSRF protection and credentials
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export default {
  data() {
    return {
      formData: {
        players: [],
        winner: "",
        loser: "",
        location: "",
      },
      customPlayerName: "", // Added property for custom player name
      customPlayerName2: "",
      customPlayerName3: "",
      successMessage: "", // Add a new data property for success message
      validationMessage: "",
      location: {
        latitude: null,
        longitude: null,
        address: null,
      },
    };
  },
  computed: {
    isFormValid() {
      // Check if winner, second, and loser are in the players array
      // and if winner, second, third, and loser are different players
      return (
        this.formData.players.includes(this.formData.winner) &&
        this.formData.players.includes(this.formData.second) &&
        this.formData.players.includes(this.formData.third) &&
        this.formData.players.includes(this.formData.loser) &&
        this.formData.winner !== this.formData.second &&
        this.formData.winner !== this.formData.third &&
        this.formData.winner !== this.formData.loser &&
        this.formData.second !== this.formData.third &&
        this.formData.second !== this.formData.loser &&
        this.formData.third !== this.formData.loser
      );
    },
  },
  created() {
    document.title = 'Big 2 Form'; // Set the new tab title
    // Call getLocation when the component is created
    this.getLocation()
      .then(() => {
        // Call reverse geocode to get the address
        this.reverseGeocode();
      })
      .catch((error) => {
        console.error('Error getting location:', error);
      });
  },
  watch: {
  'formData.players': {
    handler() {
      // Sort formData.players alphabetically whenever it changes
      this.sortPlayersAlphabetically();
    },
    deep: true, // Watch for changes in nested arrays
    },
    'location.address': {
    handler() {
      this.formData.location = this.location.address;
    },
    deep: true, // Watch for changes in nested arrays
    },
    
  },
  
  methods: {
    handleSubmit() {
      if (this.formData.players.length !== 4) {
        this.successMessage = ""; // Clear success message
        this.validationMessage = "Please select exactly 4 players."; // Set validation message
        return; // Prevent form submission
      }

      // Loop through formData.players to validate player names
      for (const playerName of this.formData.players) {
        // Check if the player name contains only letters
        if (!/^[A-Za-z ]+$/.test(playerName)) {
          this.successMessage = "";
          this.validationMessage = "Please enter player names with only letters.";
          return; // Prevent form submission
        }
      }

      // if 4 players and checked but winner and loser arent selected from the players
      if(!this.isFormValid && this.formData.players.length == 4) {
        this.successMessage = "";
        this.validationMessage = "Please select valid winner and loser from the players list or you can't select the same person multiple times.";
        return; // Prevent form submission
      }
      
      // Send a POST request using Axios
      axios
        .post('/api/big2-statistics/', this.formData)
        .then((response) => {
          // Handle success
          this.successMessage = "Data successfully posted"; // Set success message
          this.validationMessage = ""; // Clear validation message on success
          console.log("Data successfully posted", response.data);

          // Reset individual properties without affecting players array
          this.formData.winner = "";
          this.formData.second = "";
          this.formData.third = "";
          this.formData.loser = "";

          // Make the success message disappear after 1.5 sec
          setTimeout(() => {
            this.successMessage = "";
          }, 1500);
        })
        .catch((error) => {
          // Handle errors
          console.error("Error:", error);
          this.successMessage = ""; // Clear success message on error
        });
    },

    sanitizeInput(input) {
      // Use DOMPurify to sanitize the input
      return DOMPurify.sanitize(input);
    },

    getLocation() {
      return new Promise((resolve, reject) => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Successfully obtained the location
              this.location.latitude = position.coords.latitude;
              this.location.longitude = position.coords.longitude;
              resolve(); // Resolve the promise
            },
            (error) => {
              // Handle any errors here
              console.error('Error getting location:', error);
              reject(error); // Reject the promise in case of an error
            }
          );
        } else {
          console.error('Geolocation is not supported in this browser.');
          reject(new Error('Geolocation not supported')); // Reject the promise
        }
      });
    },

    async reverseGeocode() {
      const { latitude, longitude } = this.location;
      try {
        const response = await axios.get(
          `/api/reverse-geocode/?latitude=${latitude}&longitude=${longitude}`
        );
        if (response.data.status === 'OK') {
          const formattedAddress = response.data.results[0].formatted_address;
          // Update your component's data with the geocoded address
          this.location.address = formattedAddress;
        } else {
          console.error('Reverse geocoding request failed:', response.data.status);
        }
      } catch (error) {
        console.error('Error reverse geocoding:', error);
      }
    },

    // Add a method to sort formData.players alphabetically
    sortPlayersAlphabetically() {
      this.formData.players.sort((a, b) => {
        return a.localeCompare(b); // Use localeCompare for correct alphabetical sorting
      });
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Formula1-Bold';
  src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

.addressText{
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 12px;
}

.playerHeading{
  font-family: 'Formula1-Bold', sans-serif;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: 30px;
}

input[type="text"] {
  width: 80px;
  display: block;
  margin-left: 10px;
  margin-bottom: 12px;
  text-align: center;
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 10px;
}

input[type="checkbox"] {
  width: 20px; /* Set a consistent width for checkboxes */
  height: 20px; /* Set a consistent height for checkboxes */
  margin-right: 5px; /* Add space between checkbox and label */
}

form {
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Box shadow with a slight transparency */
  padding: 20px 0; /* Add top and bottom padding, left and right padding set to 0 */
  transition: box-shadow 0.3s ease; /* Smooth transition for the box shadow */
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 12px;
}

form:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Increase box shadow on hover for a more pronounced floaty effect */
}
.checkbox-group {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.checkbox-item {
  flex: 0 0 calc(33.33% - 10px); /* Adjust the percentage as needed */
  margin-right: 5px; /* Add spacing between checkboxes */
  margin-left: 5px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc; /* Add bottom border */
}

label {
  display: block;
  margin-bottom: 5px; /* Add some spacing between labels */
}

.dropdown-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust as needed for spacing between dropdowns */
}

.select-container {
  flex: 0 0 calc(50% - 10px); /* Adjust the percentage for three columns and add spacing */
  margin-bottom: 10px; /* Add spacing between rows */
}

select {
  width: 50%; /* Make the dropdowns fill the width of their container */
  box-sizing: border-box; /* Include padding and border in the width */
  font-family: 'Formula1-Regular', sans-serif;
}

h2{
  text-align: center;
  margin-top: -30px;
  margin-bottom: 50px;
}

h4{
  text-align: center;
  margin-top: 12px;
  margin-bottom: 5px;
}

p {
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 12px;
}

button{
  margin-top: 15px;
}
</style>