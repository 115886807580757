<template>
  <div class="gamesPlayed">
    <h3>Shithead Stats</h3>
    <p class="apiLength">Total Games Played: {{ apiData.length }}</p>
  </div>

  <div class="playerAverageFinishingPosition">
    <table>
    <thead>
      <tr>
        <th>#</th>
        <th>Player</th>
        <th>Average Position</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(player, index) in playerSortedAveragePosition" :key="player.name" :class="getRowClass(index)">
        <td>{{ index + 1 }}</td>
        <td>{{ player.name }}</td>
        <td>{{ player.averageFinishingPosition }}</td>
      </tr>
    </tbody>
  </table>
  </div>

  <button class="collapsible" @click="toggle(0)">Win Percentage  <i :class="['fas', isOpen[0] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[0] }">
    <div class="playerWinPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Win Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedWinPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.winPercentage }}% ({{ player.wins }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <button class="collapsible" @click="toggle(1)">Second Percentage <i :class="['fas', isOpen[1] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[1] }">
    <div class="playerSecondPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Second Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSecondPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.secondPercentage }}% ({{ player.seconds }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(2)">Third Percentage <i :class="['fas', isOpen[2] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[2] }">
    <div class="playerThirdPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Third Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedThirdPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.thirdPercentage }}% ({{ player.thirds }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(3)">Fourth Percentage <i :class="['fas', isOpen[3] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[3] }">
    <div class="playerFourthPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Fourth Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedFourthPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.fourthPercentage }}% ({{ player.fourths }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(4)">Fifth Percentage <i :class="['fas', isOpen[4] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[4] }">
    <div class="playerFifthPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Fifth Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedFifthPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.fifthPercentage }}% ({{ player.fifths }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(5)">Sixth Percentage <i :class="['fas', isOpen[5] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[5] }">
    <div class="playerSixthPercentage">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Sixth Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSixthPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.sixthPercentage }}% ({{ player.sixths }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(6)">Wins <i :class="['fas', isOpen[6] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[6] }">
    <div class="playerWins">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Wins</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedWins" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.wins }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(7)">Seconds <i :class="['fas', isOpen[7] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[7] }">
    <div class="playerSeconds">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Seconds</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSeconds" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.seconds }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <button class="collapsible" @click="toggle(8)">Thirds <i :class="['fas', isOpen[8] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[8] }">
    <div class="playerThirds">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Thirds</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedThirds" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.thirds }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(9)">Fourths<i :class="['fas', isOpen[9] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[9] }">
    <div class="playerFourths">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Fourths</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedFourths" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.fourths }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(10)">Fifths <i :class="['fas', isOpen[10] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[10] }">
    <div class="playerFifths">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Fifths</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedFifths" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.fifths }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(11)">Sixths <i :class="['fas', isOpen[11] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[11] }">
    <div class="playerSixths">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Sixths</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSixths" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.sixths }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <button class="collapsible" @click="toggle(12)">Games Played <i :class="['fas', isOpen[12] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[12] }">
    <div class="playerGamesPlayed">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>Games Played</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedGamesPlayed" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.gamesPlayed }}/{{ apiData.length }} ({{ player.gamePercentage }}%)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';  // Import ref from 'vue'

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      apiData: [], // To store the API response
      reverseApiData: [],
      selectedPlayer: '', //store selected player from dropdown box
      playerSortedWins: [],
      playerSortedSeconds: [],
      playerSortedThirds: [],
      playerSorthedFourths: [],
      playerSorthedFifths: [],
      playerSortedSixths: [],
      playerSortedGamesPlayed: [],
      playerSortedWinPercentage: [],
      playerSortedSecondPercentage: [],
      playerSortedThirdPercentage: [],
      playerSortedFourthPercentage: [],
      playerSortedFifthPercentage: [],
      playerSortedSixthPercentage: [],
      playerSortedAveragePosition: [],
      isOpen: ref([false, false, false, false, false, false, false, false, false, false, false, false, false]), // Use Vue 3's ref
    };
  },
  computed: {
    playerOptions() {
      // Use a Set to automatically remove duplicates
      const playersSet = new Set();

      // Extract player names from apiData.players
      this.apiData.forEach(dataItem => {
        dataItem.players.forEach(player => {
          playersSet.add(player);
        });
      });

      // Convert the Set back to an array and sort it
      const players = Array.from(playersSet).sort();

      return players;
    },
  },
  methods: {
    getRowClass(index) {
      switch (index) {
        case 0:
          return 'first-row';
        case 1:
          return 'second-row';
        case 2:
          return 'third-row';
        default:
          return '';
      }
    },

    toggle(index) {
      this.isOpen[index] = !this.isOpen[index]; // Directly mutate reactive array
    },

    calculatePlayerStats() {
      //store wins, seconds, thirds, and losses for each player in array
      const winningCounts = {};
      const secondCounts = {};
      const thirdCounts = {};
      const fourthCounts = {};
      const fifthCounts = {};
      const sixthCounts = {};
      const gamesPlayedCounts = {};

      // Count how many times each player has won and lost
      this.apiData.forEach(dataItem => {
        if (dataItem.winner) {
          if (winningCounts[dataItem.winner]) {
            winningCounts[dataItem.winner]++;
          } else {
            winningCounts[dataItem.winner] = 1;
          }
        }

        if (dataItem.second) {
          if (secondCounts[dataItem.second]) {
            secondCounts[dataItem.second]++;
          } else {
            secondCounts[dataItem.second] = 1;
          }
        }

        if (dataItem.third) {
          if (thirdCounts[dataItem.third]) {
            thirdCounts[dataItem.third]++;
          } else {
            thirdCounts[dataItem.third] = 1;
          }
        }

        if (dataItem.fourth) {
          if (fourthCounts[dataItem.fourth]) {
            fourthCounts[dataItem.fourth]++;
          } else {
            fourthCounts[dataItem.fourth] = 1;
          }
        }

        if (dataItem.fifth) {
          if (fifthCounts[dataItem.fifth]) {
            fifthCounts[dataItem.fifth]++;
          } else {
            fifthCounts[dataItem.fifth] = 1;
          }
        }

        if (dataItem.sixth) {
          if (sixthCounts[dataItem.sixth]) {
            sixthCounts[dataItem.sixth]++;
          } else {
            sixthCounts[dataItem.sixth] = 1;
          }
        }
      });

      // Now, count the games played for each player
      this.apiData.forEach(dataItem => {
        dataItem.players.forEach(player => {
          if (gamesPlayedCounts[player]) {
            gamesPlayedCounts[player]++;
          } else {
            gamesPlayedCounts[player] = 1;
          }
        });
      });

      // Create an array of player objects with wins and losses
      const playersWithStats = Object.keys(gamesPlayedCounts).map(player => {
        const wins = winningCounts[player] || 0;
        const seconds = secondCounts[player] || 0;
        const thirds = thirdCounts[player] || 0;
        const fourths = fourthCounts[player] || 0;
        const fifths = fifthCounts[player] || 0;
        const sixths = sixthCounts[player] || 0;
        const gamesPlayed = gamesPlayedCounts[player] || 0;

        // Calculate total points based on finishing positions
        const totalPoints = wins * 1 + seconds * 2 + thirds * 3 + fourths * 4 + fifths * 5 + sixths * 6;

        // Calculate average finishing position
        const averageFinishingPosition = (totalPoints / gamesPlayed).toFixed(3);

        // Calculate win and loss percentages
        const winPercentage = ((wins / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const secondPercentage = ((seconds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const thirdPercentage = ((thirds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const fourthPercentage = ((fourths / gamesPlayed) * 100).toFixed(2);
        const fifthPercentage = ((fifths / gamesPlayed) * 100).toFixed(2);
        const sixthPercentage = ((sixths / gamesPlayed) * 100).toFixed(2);
        const gamePercentage = ((gamesPlayed / this.apiData.length) * 100).toFixed(2);
        

        return {
          name: player,
          wins,
          seconds,
          thirds,
          fourths,
          fifths,
          sixths,
          gamesPlayed,
          gamePercentage,
          winPercentage,
          secondPercentage,
          thirdPercentage,
          fourthPercentage,
          fifthPercentage,
          sixthPercentage,
          averageFinishingPosition,
        };
      });

      // Sort the players by the number of wins in descending order
      this.playerSortedWins = playersWithStats.slice().sort((a, b) => b.wins - a.wins);

      // Sort the players by the number of seconds in descending order
      this.playerSortedSeconds = playersWithStats.slice().sort((a, b) => b.seconds - a.seconds);

      // Sort the players by the number of thirds in descending order
      this.playerSortedThirds = playersWithStats.slice().sort((a, b) => b.thirds - a.thirds);

      // Sort the players by the number of fourths in descending order
      this.playerSortedFourths = playersWithStats.slice().sort((a, b) => b.fourths - a.fourths);

      // Sort the players by the number of fifths in descending order
      this.playerSortedFifths = playersWithStats.slice().sort((a, b) => b.fifths - a.fifths);

      // Sort the players by the number of sixths in descending order
      this.playerSortedSixths = playersWithStats.slice().sort((a, b) => b.sixths - a.sixths);

      // Sort the players by the number of games played in descending order
      this.playerSortedGamesPlayed = playersWithStats.slice().sort((a, b) => b.gamesPlayed - a.gamesPlayed);

      // Sort the players by winPercentage in descending order
      this.playerSortedWinPercentage = playersWithStats.slice().sort((a, b) => b.winPercentage - a.winPercentage);

      // Sort the players by secondPercentage in descending order
      this.playerSortedSecondPercentage = playersWithStats.slice().sort((a, b) => b.secondPercentage - a.secondPercentage);

      // Sort the players by thirdPercentage in descending order
      this.playerSortedThirdPercentage = playersWithStats.slice().sort((a, b) => b.thirdPercentage - a.thirdPercentage);

      // Sort the players by lossPercentage in descending order (although I could do it ascending instead)
      this.playerSortedFourthPercentage = playersWithStats.slice().sort((a, b) => b.fourthPercentage - a.fourthPercentage);

      // Sort the players by lossPercentage in descending order (although I could do it ascending instead)
      this.playerSortedFifthPercentage = playersWithStats.slice().sort((a, b) => b.fifthPercentage - a.fifthPercentage);

      // Sort the players by lossPercentage in descending order (although I could do it ascending instead)
      this.playerSortedSixthPercentage = playersWithStats.slice().sort((a, b) => b.sixthPercentage - a.sixthPercentage);

      // Sort the players by average finshing position in ascending order
      this.playerSortedAveragePosition = playersWithStats.slice().sort((a, b) => a.averageFinishingPosition - b.averageFinishingPosition);
    },
  },
  created() {
    document.title = 'Shithead Stats'; // Set the new tab title
    // Make a GET request to the API
    axios.get('https://kbcardgames.xyz/api/sh-statistics/')
      .then(response => {
        // Handle the successful response
        this.apiData = response.data;
        this.reverseApiData = response.data.reverse();

        // Calculate the top 3 winning players
        this.calculatePlayerStats();
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Formula1-Bold';
  src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

h3{
  font-family: 'Formula1-Bold', sans-serif;
  margin-top: 15px;
}

.apiLength{
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 13px;
  margin-top: -10px;
}

.playerAverageFinishingPosition{
  margin-top: 20px;
  margin-bottom: 30px;
}

.playerAverageFinishingPosition th{
  border: none;
}

.playerAverageFinishingPosition td{
  border: none;
}

/* Style even rows after the third one */
.playerAverageFinishingPosition tr:nth-child(n+4):nth-child(even) {
  background-color: #ffffff; /* or simply background-color: white; */
}

/* Style odd rows after the third one */
.playerAverageFinishingPosition tr:nth-child(n+4):nth-child(odd) {
  background-color: #f1f1f1;
}


.playerAverageFinishingPosition td:nth-child(1) {
  font-weight: bold;
}

.first-row {
  background-color: #ffd700; /* Gold color */
}
.second-row {
  background-color: #c0c0c0 ; /* Silver color */
}
.third-row {
  background-color: #cd7f32; /* Bronze color */
}

.collapsible {
  background-color: #FF0000;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  margin-bottom: 10px; /* Add margin at the bottom of each collapsible button */
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 15px;
  
}

.collapsible i {
  position: absolute; /* Absolute positioning for the icon */
  right: 50px;
  font-size: 15px; /* Adjust icon size as needed */
}

.active, .collapsible:hover {
  background-color: #b10000;
}

.content {
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.content.open {
  max-height: 625px; 
}

.playerGamesPlayed,
.playerWins,
.playerSeconds,
.playerThirds,
.playerFourths,
.playerFifths,
.playerSixths,
.playerDaysInTheLead,
.playerWinPercentage,
.playerSecondPercentage,
.playerThirdPercentage,
.playerFourthPercentage,
.playerFifthPercentage,
.playerSixthPercentage{
  margin-bottom: 30px;
}

/* Style for tables */
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dcdcdc;
}

/* Style for table headers */
th {
  background-color: #FF0000;
  color: white; /* Set font color to white */
  border: none;
  padding: 8px;
  text-align: center;
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 15px;
}

/* Style for table cells */
td {
  border: none;
  padding: 8px;
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 13px;
  color: black;
}

td i {
  position: absolute; /* Absolute positioning for the icon */
  right: 78px;
  margin-top: 2px;
}


/* Alternate row coloring */
.playerGamesPlayed tr:nth-child(even),
.playerWins tr:nth-child(even),
.playerSeconds tr:nth-child(even),
.playerThirds tr:nth-child(even),
.playerFourths tr:nth-child(even),
.playerFifths tr:nth-child(even),
.playerSixths tr:nth-child(even),
.playerDaysInTheLead tr:nth-child(even),
.playerWinPercentage tr:nth-child(even),
.playerSecondPercentage tr:nth-child(even),
.playerThirdPercentage tr:nth-child(even),
.playerFourthPercentage tr:nth-child(even),
.playerFifthPercentage tr:nth-child(even),
.playerSixthPercentage tr:nth-child(even) {
  background-color: #ffffff; /* or simply background-color: white; */
}

.playerGamesPlayed tr:nth-child(odd),
.playerWins tr:nth-child(odd),
.playerSeconds tr:nth-child(odd),
.playerThirds tr:nth-child(odd),
.playerFourths tr:nth-child(odd),
.playerFifths tr:nth-child(odd),
.playerSixths tr:nth-child(odd),
.playerDaysInTheLead tr:nth-child(odd),
.playerWinPercentage tr:nth-child(odd),
.playerSecondPercentage tr:nth-child(odd),
.playerThirdPercentage tr:nth-child(odd),
.playerFourthPercentage tr:nth-child(odd),
.playerFifthPercentage tr:nth-child(odd),
.playerSixthPercentage tr:nth-child(odd) {
  background-color: #f1f1f1;
}

/* Last cell in each row (column) */
.playerAverageFinishingPosition td:last-child,
.playerGamesPlayed td:last-child,
.playerWins td:last-child,
.playerSeconds td:last-child,
.playerThirds td:last-child,
.playerFourths td:last-child,
.playerFifths td:last-child,
.playerSixths td:last-child,
.playerDaysInTheLead td:last-child,
.playerWinPercentage td:last-child,
.playerSecondPercentage td:last-child,
.playerThirdPercentage td:last-child,
.playerFourthPercentage td:last-child,
.playerFifthPercentage td:last-child,
.playerSixthPercentage td:last-child {
  width: 125px;
}

/* First cell in each row (column) */
.playerGamesPlayed td:first-child,
.playerWins td:first-child,
.playerSeconds td:first-child,
.playerThirds td:first-child,
.playerFourths td:first-child,
.playerFifths td:first-child,
.playerSixths td:first-child,
.playerDaysInTheLead td:first-child,
.playerWinPercentage td:first-child,
.playerSecondPercentage td:first-child,
.playerThirdPercentage td:first-child,
.playerFourthPercentage td:first-child,
.playerFifthPercentage td:first-child,
.playerSixthPercentage td:first-child {
  font-weight: bold;
}
</style>
