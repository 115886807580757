<template>
  <h3>Shithead Results</h3>
    <div class="databaseTable">
      <table>
        <thead>
          <tr>
            <th>Winner</th>
            <th>Second</th>
            <th>Third</th>
            <th>Fourth</th>
            <th>Fifth</th>
            <th>Sixth</th>
            <th>Datetime</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dataItem in reverseApiData" :key="dataItem.id">
            <td>{{ dataItem.winner }}</td>
            <td>{{ dataItem.second }}</td>
            <td>{{ dataItem.third }}</td>
            <td>{{ dataItem.fourth }}</td>
            <td>{{ dataItem.fifth }}</td>
            <td>{{ dataItem.sixth }}</td>
            <td>{{ formatDateTime(dataItem.created_at) }}</td>
            <td>{{ dataItem.location }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'ResultsPage',
    props: {
      msg: String
    },
    data() {
      return {
        apiData: [], // To store the API response
        reverseApiData: [],

      };
    },
    
    methods: {
      //display time in simple date and time format
      formatDateTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);
        const date = dateTime.toLocaleDateString();
        const time = dateTime.toLocaleTimeString();
        return `${date} ${time}`;
      },
  
      formatPlayers(playersArray) { //get rid of square brackets and quotes
        return playersArray.join(', ');
      },
    },
    created() {
      document.title = 'Shithead Results'; // Set the new tab title
      // Make a GET request to the API
      axios.get('https://kbcardgames.xyz/api/sh-statistics/')
        .then(response => {
          // Handle the successful response
          this.apiData = response.data;
          this.reverseApiData = response.data.reverse();
        })
        .catch(error => {
          // Handle any errors
          console.error('Error:', error);
        });
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
  }

  @font-face {
    font-family: 'Formula1-Bold';
    src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
  }

  h3{
    font-family: 'Formula1-Bold', sans-serif;
  }

  .databaseTable{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* Style for tables */
  table {
    border-collapse: collapse;
    width: 100%;
    color: black;
  }
  
  /* Style for table headers */
  th {
    background-color: #FF0000;
    color: white; /* Set font color to white */
    border: 1px solid black;
    padding: 8px;
    text-align: center;
    font-weight: bold;
    font-family: 'Formula1-Regular', sans-serif;
    font-size: 12.5px;
  }
  
  /* Style for table cells */
  td {
    border: 1px solid black;
    padding: 8px;
    font-family: 'Formula1-Regular', sans-serif;
    font-size: 12px;
  }
  
  /* Hover effect for rows */
  table tr:hover {
    background-color: #ddd;
  }

  </style>
  