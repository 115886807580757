<template>
  <div class="gamesPlayed">
    <h3>Big 2 Stats</h3>
    <p class="apiLength">Total Games Played: {{ apiData.length }}</p>
  </div>

  <div class="playerAverageFinishingPosition">
    <table>
    <thead>
      <tr>
        <th>Pos</th>
        <th>Player</th>
        <th>Average Position</th>
        <th>Form</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(player, index) in playerSortedAveragePosition" :key="player.name" :class="getRowClass(index)">
        <td>{{ index + 1 }}</td>
        <td>{{ player.name }}</td>
        <td>{{ player.averageFinishingPosition }}</td>
        <td class="form-column">
          <span v-if="getPlayerForm(player.name, player.averageFinishingPosition) < 0">
            <i class="fas fa-arrow-up" style="color: green;"></i>
            {{ getPlayerForm(player.name, player.averageFinishingPosition) }}
          </span>
          <span v-else-if="getPlayerForm(player.name, player.averageFinishingPosition) > 0">
            <i class="fas fa-arrow-down" style="color: red;"></i>
            {{ getPlayerForm(player.name, player.averageFinishingPosition) }}
          </span>
          <span v-else>
            {{ getPlayerForm(player.name, player.averageFinishingPosition) }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
  </div>

  <button class="collapsible" @click="toggle(0)">Win Percentage  <i :class="['fas', isOpen[0] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[0] }">
    <div class="playerWinPercentage">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Win Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedWinPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.winPercentage }}% <br> ({{ player.wins }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  
  
  <button class="collapsible" @click="toggle(1)">Second Percentage <i :class="['fas', isOpen[1] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[1] }">
    <div class="playerSecondPercentage">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Second Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSecondPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.secondPercentage }}% <br> ({{ player.seconds }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <button class="collapsible" @click="toggle(2)">Third Percentage <i :class="['fas', isOpen[2] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[2] }">
    <div class="playerThirdPercentage">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Third Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedThirdPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.thirdPercentage }}% <br> ({{ player.thirds }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button class="collapsible" @click="toggle(3)">Loss Percentage <i :class="['fas', isOpen[3] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[3] }">
    <div class="playerLossPercentage">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Loss Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedLossPercentage" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.lossPercentage }}% <br> ({{ player.losses }}/{{ player.gamesPlayed }})</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(4)">Wins <i :class="['fas', isOpen[4] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[4] }">
    <div class="playerWins">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Wins</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedWins" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.wins }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(5)">Seconds <i :class="['fas', isOpen[5] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[5] }">
    <div class="playerSeconds">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Seconds</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedSeconds" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.seconds }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(6)">Thirds <i :class="['fas', isOpen[6] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[6] }">
    <div class="playerThirds">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Thirds</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedThirds" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.thirds }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

  <button class="collapsible" @click="toggle(7)">Losses <i :class="['fas', isOpen[7] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[7] }">
    <div class="playerLosses">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Losses</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedLosses" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.losses }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <button class="collapsible" @click="toggle(8)">Days In The Lead <i :class="['fas', isOpen[8] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[8] }">
    <div class="playerDaysInTheLead">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Days In The Lead</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in sortedLowestAverageCounts" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.days }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <button class="collapsible" @click="toggle(9)">Games Played <i :class="['fas', isOpen[9] ? 'fa-minus' : 'fa-plus']"></i></button>
  <div class="content" :class="{ open: isOpen[9] }">
    <div class="playerGamesPlayed">
      <table>
        <thead>
          <tr>
            <th>Pos</th>
            <th>Player</th>
            <th>Games Played</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player, index) in playerSortedGamesPlayed" :key="player.name">
            <td>{{ index + 1 }}</td>
            <td>{{ player.name }}</td>
            <td>{{ player.gamesPlayed }}/{{ apiData.length }} <br> ({{ player.gamePercentage }}%)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref } from 'vue';  // Import ref from 'vue'

export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  data() {
    return {
      apiData: [], // To store the API response
      playerStatisticsData: [],
      selectedPlayer: '', //store selected player from dropdown box
      playerSortedWins: [],
      playerSortedSeconds: [],
      playerSortedThirds: [],
      playerSortedLosses: [],
      playerSortedGamesPlayed: [],
      playerSortedWinPercentage: [],
      playerSortedSecondPercentage: [],
      playerSortedThirdPercentage: [],
      playerSortedLossPercentage: [],
      playerSortedAveragePosition: [],
      sortedLowestAverageCounts: [], // Number of days each player had the lowest average
      isOpen: ref([false, false, false, false, false, false, false, false, false, false]), // Use Vue 3's ref
    };
  },
  computed: {
    playerOptions() {
      // Use a Set to automatically remove duplicates
      const playersSet = new Set();

      // Extract player names from apiData.players
      this.apiData.forEach(dataItem => {
        dataItem.players.forEach(player => {
          playersSet.add(player);
        });
      });

      // Convert the Set back to an array and sort it
      const players = Array.from(playersSet).sort();

      return players;
    },
  },
  methods: {
    getLatestRecord(playerName, apiData) {
      // Filter records for the specific player
      const playerRecords = apiData.filter(record => record.player === playerName);

      // Sort the records by the 'created_at' property in descending order
      playerRecords.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      // Return the latest record or null if no records are found
      return playerRecords.length > 0 ? playerRecords[0] : null;
    },

    getRowClass(index) {
      switch (index) {
        case 0:
          return 'first-row';
        case 1:
          return 'second-row';
        case 2:
          return 'third-row';
        default:
          return '';
      }
    },

    toggle(index) {
      this.isOpen[index] = !this.isOpen[index]; // Directly mutate reactive array
    },

    calculatePlayerStats(apiData) {
      //store wins, seconds, thirds, and losses for each player in array
      const winningCounts = {};
      const secondCounts = {};
      const thirdCounts = {};
      const losingCounts = {};
      const gamesPlayedCounts = {};

      // Count how many times each player has won and lost
      apiData.forEach(dataItem => {
        if (dataItem.winner) {
          if (winningCounts[dataItem.winner]) {
            winningCounts[dataItem.winner]++;
          } else {
            winningCounts[dataItem.winner] = 1;
          }
        }

        if (dataItem.second) {
          if (secondCounts[dataItem.second]) {
            secondCounts[dataItem.second]++;
          } else {
            secondCounts[dataItem.second] = 1;
          }
        }

        if (dataItem.third) {
          if (thirdCounts[dataItem.third]) {
            thirdCounts[dataItem.third]++;
          } else {
            thirdCounts[dataItem.third] = 1;
          }
        }

        if (dataItem.loser) {
          if (losingCounts[dataItem.loser]) {
            losingCounts[dataItem.loser]++;
          } else {
            losingCounts[dataItem.loser] = 1;
          }
        }
      });

      // Now, count the games played for each player
      apiData.forEach(dataItem => {
        dataItem.players.forEach(player => {
          if (gamesPlayedCounts[player]) {
            gamesPlayedCounts[player]++;
          } else {
            gamesPlayedCounts[player] = 1;
          }
        });
      });

      // Create an array of player objects with wins and losses
      const playersWithStats = Object.keys(gamesPlayedCounts).map(player => {
        const wins = winningCounts[player] || 0;
        const seconds = secondCounts[player] || 0;
        const thirds = thirdCounts[player] || 0;
        const losses = losingCounts[player] || 0;
        const gamesPlayed = gamesPlayedCounts[player] || 0;

        // Calculate total points based on finishing positions
        const totalPoints = wins * 1 + seconds * 2 + thirds * 3 + losses * 4;

        // Calculate average finishing position
        const averageFinishingPosition = (totalPoints / gamesPlayed).toFixed(3);

        // Calculate win and loss percentages
        const winPercentage = ((wins / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const secondPercentage = ((seconds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const thirdPercentage = ((thirds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const lossPercentage = ((losses / gamesPlayed) * 100).toFixed(2); 
        const gamePercentage = ((gamesPlayed / this.apiData.length) * 100).toFixed(2);
        

        return {
          name: player,
          wins,
          seconds,
          thirds,
          losses,
          gamesPlayed,
          gamePercentage,
          winPercentage,
          secondPercentage,
          thirdPercentage,
          lossPercentage,
          averageFinishingPosition,
        };
      });

      // Sort the players by the number of wins in descending order
      this.playerSortedWins = playersWithStats.slice().sort((a, b) => b.wins - a.wins);

      // Sort the players by the number of seconds in descending order
      this.playerSortedSeconds = playersWithStats.slice().sort((a, b) => b.seconds - a.seconds);

      // Sort the players by the number of thirds in descending order
      this.playerSortedThirds = playersWithStats.slice().sort((a, b) => b.thirds - a.thirds);

      // Sort the players by the number of losses in descending order
      this.playerSortedLosses = playersWithStats.slice().sort((a, b) => b.losses - a.losses);

      // Sort the players by the number of games played in descending order
      this.playerSortedGamesPlayed = playersWithStats.slice().sort((a, b) => b.gamesPlayed - a.gamesPlayed);

      // Sort the players by winPercentage in descending order
      this.playerSortedWinPercentage = playersWithStats.slice().sort((a, b) => b.winPercentage - a.winPercentage);

      // Sort the players by secondPercentage in descending order
      this.playerSortedSecondPercentage = playersWithStats.slice().sort((a, b) => b.secondPercentage - a.secondPercentage);

      // Sort the players by thirdPercentage in descending order
      this.playerSortedThirdPercentage = playersWithStats.slice().sort((a, b) => b.thirdPercentage - a.thirdPercentage);

      // Sort the players by lossPercentage in descending order (although I could do it ascending instead)
      this.playerSortedLossPercentage = playersWithStats.slice().sort((a, b) => b.lossPercentage - a.lossPercentage);

      // Sort the players by average finshing position in ascending order
      this.playerSortedAveragePosition = playersWithStats.slice().sort((a, b) => a.averageFinishingPosition - b.averageFinishingPosition);

      return playersWithStats;
    },

    getPlayerForm(playerName, playerTodayAverage) {
      // else if player1 selected, return wins, seconds, thirds, losses
      const yesterdayRecord = this.getLatestRecord(playerName, this.playerStatisticsData);

      if (yesterdayRecord) {
        const form = (playerTodayAverage - yesterdayRecord.averageFinishingPosition).toFixed(3);

        // return score difference from todays average & yesterdays average
        return form
      }
      else {
        return 0.00;
      }
    },

    processPlayerStatisticsData() {
      const lowestAverageDays = {}; // To store the lowest average player for each date

      // Iterate over the data to find the lowest average for each date
      this.playerStatisticsData.forEach(entry => {
        const currentDate = entry.created_at.split('T')[0]; // Extract the date part (YYYY-MM-DD)
        const currentPlayer = entry.player; // Player's name
        const currentAverage = entry.averageFinishingPosition; // Average finishing position

        // Initialize the date entry if it doesn't exist
        if (!lowestAverageDays[currentDate]) {
          lowestAverageDays[currentDate] = {
            player: currentPlayer,
            average: currentAverage,
          };
        } else {
          // If the current average is lower, update the player for this date
          if (currentAverage < lowestAverageDays[currentDate].average) {
            lowestAverageDays[currentDate] = {
              player: currentPlayer,
              average: currentAverage,
            };
          }
        }
      });

      // Count how many times each player had the lowest average
      const lowestAverageCounts = {};

      Object.values(lowestAverageDays).forEach(({ player }) => {
        if (!lowestAverageCounts[player]) {
          lowestAverageCounts[player] = 0;
        }
        lowestAverageCounts[player]++;
      });

      // Convert the object into an array and sort it by the count (descending)
      const sortedLowestAverageCounts = Object.entries(lowestAverageCounts)
        .sort((a, b) => b[1] - a[1]) // Sort by the number of days (descending)
        .map(([name, days]) => ({ name, days })); // Convert back to array of objects

      // Store the sorted result in a data property
      this.sortedLowestAverageCounts = sortedLowestAverageCounts;
    },
  },
  created() {
  document.title = 'Big 2 Stats'; // Set the new tab title
  // Make a GET request to the API
  axios.get('https://kbcardgames.xyz/api/big2-statistics/')
    .then(response => {
      // Handle the successful response
      this.apiData = response.data;

      // Calculate the top 3 winning players
      const playerStats = this.calculatePlayerStats(this.apiData);

      axios.post('https://kbcardgames.xyz/api/receive-data/', { playerStats })
        .then(response => {
          console.log('Player stats sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending player stats:', error);
        });
    })
    .catch(error => {
      // Handle any errors
      console.error('Error:', error);
    });

    axios.get('https://kbcardgames.xyz/api/player-statistics/')
      .then(response => {
        // Handle the successful response
        this.playerStatisticsData = response.data;
        this.processPlayerStatisticsData(); // Process data after fetching

        // Log success to the console
        console.log('playerStatisticsData API request successful:', response);
      })
      .catch(error => {
        // Handle any errors
        console.error('Error:', error);
      });
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Formula1-Bold';
  src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

h3{
  font-family: 'Formula1-Bold', sans-serif;
  margin-top: 15px;
}

.apiLength{
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 13px;
  margin-top: -10px;
}

.playerAverageFinishingPosition{
  margin-top: 20px;
  margin-bottom: 30px;
}

.playerAverageFinishingPosition th{
  border: none;
}

.playerAverageFinishingPosition td{
  border: none;
}

/* Style even rows after the third one */
.playerAverageFinishingPosition tr:nth-child(n+4):nth-child(even) {
  background-color: #ffffff; /* or simply background-color: white; */
}

/* Style odd rows after the third one */
.playerAverageFinishingPosition tr:nth-child(n+4):nth-child(odd) {
  background-color: #f1f1f1;
}

.playerAverageFinishingPosition td:nth-child(2) {
  width: 90px;
}

.playerAverageFinishingPosition td:nth-child(1) {
  font-weight: bold;
}

.playerAverageFinishingPosition td:last-child {
  width: 75px; /* Adjust this value to make the column smaller */
  text-align: center; /* Center-align the content if desired */
}

.first-row {
  background-color: #ffd700; /* Gold color */
}
.second-row {
  background-color: #c0c0c0 ; /* Silver color */
}
.third-row {
  background-color: #cd7f32; /* Bronze color */
}

.collapsible {
  background-color: #FF0000;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  margin-bottom: 10px; /* Add margin at the bottom of each collapsible button */
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 15px;
  
}

.collapsible i {
  position: absolute; /* Absolute positioning for the icon */
  right: 50px;
  font-size: 15px; /* Adjust icon size as needed */
}

.active, .collapsible:hover {
  background-color: #b10000;
}

.content {
  padding: 0 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.content.open {
  max-height: 625px; 
}

.playerGamesPlayed,
.playerWins,
.playerSeconds,
.playerThirds,
.playerLosses,
.playerDaysInTheLead,
.playerWinPercentage,
.playerLossPercentage,
.playerSecondPercentage,
.playerThirdPercentage{
  margin-bottom: 30px;
}

/* Style for tables */
table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #dcdcdc;
}

/* Style for table headers */
th {
  background-color: #FF0000;
  color: white; /* Set font color to white */
  border: none;
  padding: 8px;
  text-align: center;
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 15px;
}

/* Style for table cells */
td {
  border: none;
  padding: 8px;
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 13px;
  color: black;
}

td i {
  position: absolute; /* Absolute positioning for the icon */
  right: 78px;
  margin-top: 2px;
}

.playerGamesPlayed tr:nth-child(even),
.playerWins tr:nth-child(even),
.playerSeconds tr:nth-child(even),
.playerThirds tr:nth-child(even),
.playerLosses tr:nth-child(even),
.playerDaysInTheLead tr:nth-child(even),
.playerWinPercentage tr:nth-child(even),
.playerLossPercentage tr:nth-child(even),
.playerSecondPercentage tr:nth-child(even),
.playerThirdPercentage tr:nth-child(even) {
  background-color: #ffffff; /* or simply background-color: white; */
}

.playerGamesPlayed tr:nth-child(odd),
.playerWins tr:nth-child(odd),
.playerSeconds tr:nth-child(odd),
.playerThirds tr:nth-child(odd),
.playerLosses tr:nth-child(odd),
.playerDaysInTheLead tr:nth-child(odd),
.playerWinPercentage tr:nth-child(odd),
.playerLossPercentage tr:nth-child(odd),
.playerSecondPercentage tr:nth-child(odd),
.playerThirdPercentage tr:nth-child(odd) {
  background-color: #f1f1f1;
}

.playerWins td:last-child,
.playerSeconds td:last-child,
.playerThirds td:last-child,
.playerLosses td:last-child,
.playerDaysInTheLead td:last-child,
.playerGamesPlayed td:last-child,
.playerWinPercentage td:last-child,
.playerLossPercentage td:last-child,
.playerSecondPercentage td:last-child,
.playerThirdPercentage td:last-child{
  width: 125px;
}

.playerWins td:first-child,
.playerSeconds td:first-child,
.playerThirds td:first-child,
.playerLosses td:first-child,
.playerDaysInTheLead td:first-child,
.playerGamesPlayed td:first-child,
.playerWinPercentage td:first-child,
.playerLossPercentage td:first-child,
.playerSecondPercentage td:first-child,
.playerThirdPercentage td:first-child {
  font-weight: bold;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 200px; /* Adjust the width as needed */
  margin-top: 20px;
}
</style>
