import { createRouter, createWebHistory } from 'vue-router';
import Big2Form from './components/Big2Form.vue';
import HomePage from './components/HomePage.vue';
import Big2ResultsPage from './components/Big2ResultsPage.vue';
import Big2ChartsPage from './components/Big2ChartsPage.vue';
import ShForm from './components/ShForm.vue';
import ShStatsPage from './components/ShStatsPage.vue';
import ShResultsPage from './components/ShResultsPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
  path: '/big2-form',
  name: 'Big2Form',
  component: Big2Form,
  },
  {
  path: '/big2-results',
  name: 'Big2ResultsPage',
  component: Big2ResultsPage,
  },
  {
    path: '/big2-charts',
    name: 'Big2ChartsPage',
    component: Big2ChartsPage,
  },
  {
  path: '/sh-stats',
  name: 'ShStatsPage',
  component: ShStatsPage,
  },
  {
  path: '/sh-results',
  name: 'ShResultsPage',
  component: ShResultsPage,
  },
  {
  path: '/sh-form',
  name: 'ShForm',
  component: ShForm,
  },
  // Add more routes for your application
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;