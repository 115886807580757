<template>
  <h3>Big 2 Stat Charts</h3>
  <div class="dropdown-group">
    <div class="select-container">
      <div>
        <select id="first-dropdown" v-model="player1" @change="getLatestRecords(player1)">
          <option v-for="player in uniquePlayers" :key="player" :value="player">{{ player }}</option>
        </select>
      </div>
    </div>

    <div class="select-container">
      <div>
        <select id="second-dropdown" v-model="player2"  @change="getLatestRecords(player2)">
          <option v-for="player in uniquePlayers" :key="player" :value="player">{{ player }}</option>
        </select>
      </div>
    </div>

    <div class="select-container">
      <div>
        <select id="third-dropdown" v-model="player3" @change="getLatestRecords(player3)">
          <option v-for="player in uniquePlayers" :key="player" :value="player">{{ player }}</option>
        </select>
      </div>
    </div>

    <div class="select-container">
      <div>
        <select id="fourth-dropdown" v-model="player4" @change="getLatestRecords(player4)">
          <option v-for="player in uniquePlayers" :key="player" :value="player">{{ player }}</option>
        </select>
      </div>
    </div>
  </div>

  <h4 class="hideCharts">Hide Charts</h4>
  <div class="checkbox-group">
    <div class="checkbox-item">
      <input type="checkbox" v-model="hideLineChartWeekly">
      <label>Weekly Average</label>
    </div>

    <div class="checkbox-item">
      <input type="checkbox" v-model="hideLineChartDaily">
      <label>Daily Average</label>
    </div>

    <div class="checkbox-item">
      <input type="checkbox" v-model="hideBarChartPercentage">
      <label>% Stats</label>
    </div>

    <div class="checkbox-item">
      <input type="checkbox" v-model="hideBarChart">
      <label>Stats</label>
    </div>
  </div>

  <div class="page-container">
    <div :class="{ 'hidden': hideLineChartWeekly }" class="lineChart-container">
      <Line
        :data="lineChartData"
        :options="lineChartOptions" 
      />
    </div>

    <div :class="{ 'hidden': hideLineChartDaily }" class="lineChart-container">
      <Line
        :data="lineChartDailyData"
        :options="lineChartDailyOptions" 
      />
    </div>

    <div :class="{ 'hidden': hideBarChartPercentage }" class="barChart-container">
      <Bar 
        :data="barChartPercentageData" 
        :options="barChartPercentageOptions"
       />
    </div>

    <div :class="{ 'hidden': hideBarChart }" class="barChart-container">
      <Bar 
        :data="barChartData" 
        :options="barChartOptions"
       />
    </div>
    
  </div>
  
</template>
  
<script>
  import axios from 'axios';
  import { Line } from 'vue-chartjs'
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, RadialLinearScale, Tooltip, Legend, ArcElement } from 'chart.js'
  
  ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, RadialLinearScale, ArcElement, Tooltip, Legend)

  export default {
    name: 'BigChartsPage',
    components: { 
      Line,
      Bar,
    },
    props: {
      msg: String
    },
    data() {
      return {
        apiData: [], // To store the API response
        big2ApiData: [],
        playerStatsArray: [],
        // declare variables to store v-models
        player1: null,
        player2: null,
        player3: null,
        player4: null,
        // chart hiding boolean variables
        hideLineChartWeekly: false,
        hideBarChartPercentage: false,
        hideBarChart: false,
        hideLineChartDaily: false,
      };
    },
    computed: {
      uniquePlayers () {
        // Extract unique player names, sort alphabetically, and insert null at the beginning
        const allPlayers = this.apiData.map(({ player }) => player);
        const sortedPlayers = allPlayers.sort((a, b) => a.localeCompare(b));
        const uniquePlayers = [null, ...new Set(sortedPlayers)];

        return uniquePlayers;
      },
      barChartData() {
        return {
          labels: ['Wins', 'Seconds', 'Thirds', 'Losses', 'Games'],
          datasets: [
            {
              label: this.player1,
              backgroundColor: '#ff0000',
              borderColor: '#ff0000',
              data: this.getLatestRecords(this.player1),
            },
            {
              label: this.player2,
              backgroundColor: '#2387c5',
              borderColor: '#2387c5',
              data: this.getLatestRecords(this.player2),
            },
            {
              label: this.player3,
              backgroundColor: '#008000',
              borderColor: '#008000',
              data: this.getLatestRecords(this.player3),
            },
            {
              label: this.player4,
              backgroundColor: '#fcba03',
              borderColor: '#fcba03',
              data: this.getLatestRecords(this.player4),
            }
          ]
        }
      },
      barChartPercentageData() {
        return {
          labels: ['Win %', 'Second %', 'Third %', 'Loss %'],
          datasets: [
            {
              label: this.player1,
              backgroundColor: '#ff0000',
              borderColor: '#ff0000',
              data: this.getLatestPercentageRecords(this.player1),
            },
            {
              label: this.player2,
              backgroundColor: '#2387c5',
              borderColor: '#2387c5',
              data: this.getLatestPercentageRecords(this.player2),
            },
            {
              label: this.player3,
              backgroundColor: '#008000',
              borderColor: '#008000',
              data: this.getLatestPercentageRecords(this.player3),
            },
            {
              label: this.player4,
              backgroundColor: '#fcba03',
              borderColor: '#fcba03',
              data: this.getLatestPercentageRecords(this.player4),
            }
          ]
        }
      },
      //return data that corresponds to date
      lineChartData() {
        return {
          //return date labels, accounts for players not being selected
          labels: this.getDateLabels(this.player1, this.player2, this.player3, this.player4, 'weekly'),
          datasets: [
            {
              label: this.player1,
              backgroundColor: '#ff0000',
              borderColor: '#ff0000',
              //return line chart data using selected player's game dates to search for data (weekly in this case)
              data: this.getLineChartData(this.getDateLabels(this.player1, undefined, undefined, undefined, 'weekly'), this.player1),
            },
            {
              label: this.player2,
              backgroundColor: '#2387c5',
              borderColor: '#2387c5',
              data: this.getLineChartData(this.getDateLabels(this.player2, undefined, undefined, undefined, 'weekly'), this.player2),
            },
            {
              label: this.player3,
              backgroundColor: '#008000',
              borderColor: '#008000',
              data: this.getLineChartData(this.getDateLabels(this.player3, undefined, undefined, undefined, 'weekly'), this.player3),
            },
            {
              label: this.player4,
              backgroundColor: '#fcba03',
              borderColor: '#fcba03',
              data: this.getLineChartData(this.getDateLabels(this.player4, undefined, undefined, undefined, 'weekly'), this.player4),
            }
          ]
        }
      },
      lineChartDailyData() {
        return {
          //change to get daily labels TODO
          labels: this.getDateLabels(this.player1, this.player2, this.player3, this.player4,  'daily'),
          datasets: [
            {
              label: this.player1,
              backgroundColor: '#ff0000',
              borderColor: '#ff0000',
              //return line chart data using selected player's game dates to search for data (daily in this case)
              data: this.getLineChartData(this.getDateLabels(this.player1, undefined, undefined, undefined, 'daily'), this.player1),
            },
            {
              label: this.player2,
              backgroundColor: '#2387c5',
              borderColor: '#2387c5',
              data: this.getLineChartData(this.getDateLabels(this.player2, undefined, undefined, undefined, 'daily'), this.player2),
            },
            {
              label: this.player3,
              backgroundColor: '#008000',
              borderColor: '#008000',
              data: this.getLineChartData(this.getDateLabels(this.player3, undefined, undefined, undefined, 'daily'), this.player3),
            },
            {
              label: this.player4,
              backgroundColor: '#fcba03',
              borderColor: '#fcba03',
              data: this.getLineChartData(this.getDateLabels(this.player4, undefined, undefined, undefined, 'daily'), this.player4),
            }
          ]
        }
      },
      lineChartOptions() {
        return {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y: {
              min: 2,
              max: 4,
              ticks: {
                beginAtZero: false,
                autoSkip: false, 
                stepSize: 0.05, //30 steps max-min / steps you want = stepSize
              },
              reverse: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Average Finishing Position (Weekly)",
            },
          },
        }
      },
      lineChartDailyOptions() {
        return {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y: {
              min: 2,
              max: 4,
              ticks: {
                beginAtZero: false,
                autoSkip: false, 
                stepSize: 0.05, //30 steps
              },
              reverse: true,
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Average Finishing Position (Daily)",
            },
          },
        }
      },
      barChartOptions() {
        return {
          type: 'bar',
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              text: "Player Stats"
            },
          }
        }
      },
      barChartPercentageOptions() {
        return {
          type: 'bar',
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            title: {
              display: true,
              text: "Player Percentage Stats"
            },
          }
        }
      },
    },
    methods: {
      getLatestRecord(playerName, apiData) {
        // Filter records for the specific player
        const playerRecords = apiData.filter(record => record.player === playerName);

        // Sort the records by the 'created_at' property in descending order
        playerRecords.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        // Return the latest record or null if no records are found
        return playerRecords.length > 0 ? playerRecords[0] : null;
      },
      getOldestRecord(playerName, apiData) {
          // Filter records for the specific player
          const playerRecords = apiData.filter(record => record.player === playerName);

          // Sort the records by the 'created_at' property in ascending order
          playerRecords.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

          // Return the oldest record or null if no records are found
          return playerRecords.length > 0 ? playerRecords[0] : null;
      },
      getLatestRecords(player) {
        if (player == null) {
          // If player is null or undefined, return [0, 0, 0, 0]
          return [0, 0, 0, 0];
        }
        else{
          // else if player1 selected, return wins, seconds, thirds, losses
          const latestRecord = this.getLatestRecord(player, this.apiData);

          return [latestRecord.wins, latestRecord.seconds, latestRecord.thirds, latestRecord.losses, latestRecord.gamesPlayed];
        }
      },
      getLatestPercentageRecords(player) {
        if (player == null) {
          // If player is null or undefined, return [0, 0, 0, 0]
          return [0, 0, 0, 0];
        }
        else{
          // else if player1 selected, return wins, seconds, thirds, losses
          const latestRecord = this.getLatestRecord(player, this.apiData);

          return [latestRecord.winPercentage, latestRecord.secondPercentage, latestRecord.thirdPercentage, latestRecord.lossPercentage];
        }
      },
      getDateLabels(player1, player2, player3, player4, dateToggle){
        if(player1 == null && player2 == null && player3 == null  && player4 == null) {
          return ['N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'];
        }
        else if(player1 != null){
          const latestRecord = this.getLatestRecord(player1, this.apiData);
          // Check if oldestRecord and latestRecord exists to avoid errors
          if (latestRecord) {
            const newestDate = new Date(latestRecord.created_at);
            //increase newestDate by a day
            newestDate.setDate(newestDate.getDate() + 1);

            // Create an array of dates starting 5 weeks (35 days) before the latest date
            const dateArray = [];
            let currentDate = new Date(newestDate);

            if(dateToggle == 'daily'){
              currentDate.setDate(currentDate.getDate() - 16); // set current date to 16 days ago
            }
            else if(dateToggle == 'weekly'){
              currentDate.setDate(currentDate.getDate() - 105); // set current date to 15 weeks ago
            }

            while (currentDate <= newestDate) {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    year: '2-digit',
                    day: '2-digit',
                    month: 'short',
                  }).format(currentDate);

                dateArray.push(formattedDate);

                if(dateToggle == 'daily'){
                  currentDate.setDate(currentDate.getDate() + 1); //increase day by 1
                }
                else if(dateToggle == 'weekly'){
                  currentDate.setDate(currentDate.getDate() + 7); // increase day by 7 days
                }
              }

            //return array of last 7 week dates
            return dateArray;
          }
          else {
            // Return a default array if no latestRecord is found
            return ['No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date'];
          }
        }
        //load in player 2's dates if player 1 is null
        else if(player1 == null && player2 != null){
          const latestRecord = this.getLatestRecord(player2, this.apiData);

          if (latestRecord) {
            const newestDate = new Date(latestRecord.created_at);
            newestDate.setDate(newestDate.getDate() + 1);

            const dateArray = [];
            let currentDate = new Date(newestDate);
            currentDate.setDate(currentDate.getDate() - 49); 

            while (currentDate <= newestDate) {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    year: '2-digit',
                    day: '2-digit',
                    month: 'short',
                  }).format(currentDate);

                dateArray.push(formattedDate);

                // Move to the next 7 days
                currentDate.setDate(currentDate.getDate() + 7);
            }

            return dateArray;
          }
          else {
            // Return a default array if no latestRecord is found
            return ['No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date'];
          }
        }
        //load in player 3's dates if player 1 & 2 is null
        else if(player1 == null && player2 == null && player3 != null){
          const latestRecord = this.getLatestRecord(player3, this.apiData);

          if (latestRecord) {
            const newestDate = new Date(latestRecord.created_at);
            newestDate.setDate(newestDate.getDate() + 1);

            // Create an array of dates starting 5 weeks (35 days) before the latest date
            const dateArray = [];
            let currentDate = new Date(newestDate);
            currentDate.setDate(currentDate.getDate() - 49); // show 7 weeks (7 x 7)

            while (currentDate <= newestDate) {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    year: '2-digit',
                    day: '2-digit',
                    month: 'short',
                  }).format(currentDate);

                dateArray.push(formattedDate);

                // Move to the next 7 days
                currentDate.setDate(currentDate.getDate() + 7);
            }

            return dateArray;
          }
          else {
            // Return a default array if no latestRecord is found
            return ['No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date'];
          }
        }
        //load in player 4's dates if player 1 & 2 & 3 is null
        else if(player1 == null && player2 == null && player3 == null && player4 != null){
          const latestRecord = this.getLatestRecord(player4, this.apiData);

          if (latestRecord) {
            const newestDate = new Date(latestRecord.created_at);
            newestDate.setDate(newestDate.getDate() + 1);

            // Create an array of dates starting 5 weeks (35 days) before the latest date
            const dateArray = [];
            let currentDate = new Date(newestDate);
            currentDate.setDate(currentDate.getDate() - 49); // show 7 weeks (7 x 7)

            while (currentDate <= newestDate) {
                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    year: '2-digit',
                    day: '2-digit',
                    month: 'short',
                  }).format(currentDate);

                dateArray.push(formattedDate);

                // Move to the next 7 days
                currentDate.setDate(currentDate.getDate() + 7);
            }

            return dateArray;
          }
          else {
            // Return a default array if no latestRecord is found
            return ['No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date', 'No Date'];
          }
        }
      },
      //input format: Nov 26, 23, 11 PM
      parseCustomDate(dateString) {
        const dateParts = dateString.split(', ');
        const monthDay = dateParts[0];
        const hours = 23;
        const minutes = 59; // Assuming minutes are always 0 in your case
        const month = this.parseMonth(monthDay.split(' ')[0]);
        // Extract day from monthDay
        const day = parseInt(monthDay.split(' ')[1], 10);

        let year = parseInt(dateParts[1], 10);
        // Convert two-digit year to four-digit format
        year = year < 100 ? 2000 + year : year;

        // Return a new Date object
        const date = new Date(year, month - 1, day, hours, minutes);
        return date;
      },
      // Helper function to parse month abbreviations
      parseMonth(monthAbbreviation) {
          const months = {
            'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
            'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
          };
          return months[monthAbbreviation];
      },
      //take in array of 7 dates and name to return an array of average finishing positions corresponding to each date
      getLineChartData(dateArray, playerName){
        //default dates, if no player selected
        if (dateArray.every(date => date === 'N/A')) {
          return [];
        }
        else {
          // Initialize an array to store the results
          const chartData = [];

          // Loop through each date in dateArray
          for (const dateString of dateArray) {
            const dateStringObject = new Date(dateString);
            const parsedDate = this.parseCustomDate(dateString);
            const today = new Date();
            
            // Check if provided date is the same as today's date
            const isSameDay = dateStringObject.getFullYear() === today.getFullYear() &&
                              dateStringObject.getMonth() === today.getMonth() &&
                              dateStringObject.getDate() === today.getDate();
            //console.log(parsedDate);

            const year = parsedDate.getFullYear();
            const month = parsedDate.getMonth() + 1; // Months are zero-based
            const day = parsedDate.getDate();
            const formattedDateForComparison = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
            //console.log(formattedDateForComparison)

            // Search for a record in apiArray using the formattedDateForComparison, TO DO ALSO make sure the player name matches up (property is .player)
            const matchingRecord = this.apiData.find(
              record =>
                record.created_at.startsWith(formattedDateForComparison) &&
                record.player === playerName
            );

            // Add the relevant data to chartData
            if (matchingRecord) {
              chartData.push(matchingRecord.averageFinishingPosition); 
            } 
            //if no matching record and date label is today, push current average score from player stats array
            else if(!matchingRecord && isSameDay) {
              //return current player stats from player stats array
              const playerStats = this.playerStatsArray.find(player => player.name === playerName);
              chartData.push(playerStats.averageFinishingPosition);
            }
            else {
              chartData.push(null); // If no matching record found, insert null
            }
          }

          return chartData;
        }
      },
      calculatePlayerStats(apiData) {
      //store wins, seconds, thirds, and losses for each player in array
      const winningCounts = {};
      const secondCounts = {};
      const thirdCounts = {};
      const losingCounts = {};
      const gamesPlayedCounts = {};

      // Count how many times each player has won and lost
      apiData.forEach(dataItem => {
        if (dataItem.winner) {
          if (winningCounts[dataItem.winner]) {
            winningCounts[dataItem.winner]++;
          } else {
            winningCounts[dataItem.winner] = 1;
          }
        }

        if (dataItem.second) {
          if (secondCounts[dataItem.second]) {
            secondCounts[dataItem.second]++;
          } else {
            secondCounts[dataItem.second] = 1;
          }
        }

        if (dataItem.third) {
          if (thirdCounts[dataItem.third]) {
            thirdCounts[dataItem.third]++;
          } else {
            thirdCounts[dataItem.third] = 1;
          }
        }

        if (dataItem.loser) {
          if (losingCounts[dataItem.loser]) {
            losingCounts[dataItem.loser]++;
          } else {
            losingCounts[dataItem.loser] = 1;
          }
        }
      });

      // Now, count the games played for each player
      apiData.forEach(dataItem => {
        dataItem.players.forEach(player => {
          if (gamesPlayedCounts[player]) {
            gamesPlayedCounts[player]++;
          } else {
            gamesPlayedCounts[player] = 1;
          }
        });
      });

      // Create an array of player objects with wins and losses
      const playersWithStats = Object.keys(gamesPlayedCounts).map(player => {
        const wins = winningCounts[player] || 0;
        const seconds = secondCounts[player] || 0;
        const thirds = thirdCounts[player] || 0;
        const losses = losingCounts[player] || 0;
        const gamesPlayed = gamesPlayedCounts[player] || 0;

        // Calculate total points based on finishing positions
        const totalPoints = wins * 1 + seconds * 2 + thirds * 3 + losses * 4;

        // Calculate average finishing position
        const averageFinishingPosition = (totalPoints / gamesPlayed).toFixed(3);

        // Calculate win and loss percentages
        const winPercentage = ((wins / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const secondPercentage = ((seconds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const thirdPercentage = ((thirds / gamesPlayed) * 100).toFixed(2); // Rounds to 2 decimal points
        const lossPercentage = ((losses / gamesPlayed) * 100).toFixed(2); 
        const gamePercentage = ((gamesPlayed / this.apiData.length) * 100).toFixed(2);
        

        return {
          name: player,
          wins,
          seconds,
          thirds,
          losses,
          gamesPlayed,
          gamePercentage,
          winPercentage,
          secondPercentage,
          thirdPercentage,
          lossPercentage,
          averageFinishingPosition,
        };
      });

      return playersWithStats;
    },
    },
    created() {
      document.title = 'Big 2 Charts'; // Set the new tab title
      // Make a GET request to the API
      axios.get('https://kbcardgames.xyz/api/player-statistics/')
        .then(response => {
          // Handle the successful response
          this.apiData = response.data;

          // Log success to the console
          console.log('API request successful:', response);
        })
        .catch(error => {
          // Handle any errors
          console.error('Error:', error);
        });

      // Make a GET request to the second API
      axios.get('https://kbcardgames.xyz/api/big2-statistics/')
        .then(response => {
          // Handle the successful response for the second API
          this.big2ApiData = response.data; // Assuming you have a variable like big2ApiData to store the second API response

          //get array with containing player stats (1 element per player)
          this.playerStatsArray = this.calculatePlayerStats(this.big2ApiData);
          console.log(this.playerStatsArray);
          // Log success to the console
          console.log('Second API request successful:', response);
        })
        .catch(error => {
          // Handle any errors for the second API
          console.error('Error with the second API:', error);
        });
    },
  }
</script>
  

<style scoped>
@font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Formula1-Bold';
  src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

h3{
  font-family: 'Formula1-Bold', sans-serif;
  margin-top: 15px;
}

.hideCharts {
  margin-bottom: 2px;
  font-size: 14px; 
  font-family: 'Formula1-regular', sans-serif;
  margin-top: 20px;
}

.dropdown-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Adjust as needed for spacing between dropdowns */
}

.select-container {
  flex: 0 0 calc(50% - 10px); /* Adjust the percentage for three columns and add spacing */
  margin-top: 5px;
  margin-bottom: 0px; /* Add spacing between rows */
}

select {
  width: 50%; /* Make the dropdowns fill the width of their container */
  box-sizing: border-box; /* Include padding and border in the width */
  margin-top: 10px;
  font-family: 'Formula1-Regular', sans-serif;
}

label {
  font-size: 10px;
  font-family: 'Formula1-regular', sans-serif;
  display: block;
}

.checkbox-group {
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 10px;
  padding: 5px 0; /* Add top and bottom padding, left and right padding set to 0 */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.checkbox-item {
  flex: 0 0 calc(25% - 10px); /* Adjust the percentage as needed */
  margin-right: 10px; /* Add spacing between checkboxes */
}

.hidden {
  display: none;
}

.barChart-container {
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 10px;
  position: relative;
  margin-top: 12px; /* Adjust as needed to add some spacing between the charts */
  margin-bottom: 50px;
  height: 400px;
  width: 360px;
}

.lineChart-container{
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 10px;
  position: relative;
  margin-top: 12px;
  margin-bottom: 50px;
  height: 750px;
  width: 360px;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh; /* minimum height, height will adjust automatically if threshold is crossed */
}
</style>
  