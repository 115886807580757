<template>
  <div id="app">
    <div class="dropdown" ref="dropdown">
      <button class="dropbtn" @click="toggleDropdown">
        <i class="fas fa-bars"></i>
      </button>
      <div class="dropdown-content">
        <router-link to="/">Big 2 Stats</router-link>
        <router-link to="/big2-form">Big 2 Form</router-link>
        <router-link to="/big2-results">Big 2 Results</router-link>
        <router-link to="/big2-charts">Big 2 Charts</router-link>
        <router-link to="/sh-stats">Shithead Stats</router-link>
        <router-link to="/sh-form">Shithead Form</router-link>
        <router-link to="/sh-results">Shithead Results</router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    toggleDropdown() {
      const dropdownContent = this.$refs.dropdown.querySelector('.dropdown-content');
      dropdownContent.classList.toggle('show');
    },
    closeDropdown(event) {
      const dropdownContent = this.$refs.dropdown.querySelector('.dropdown-content');
      if (event.target !== dropdownContent && !dropdownContent.contains(event.target)) {
        dropdownContent.classList.remove('show');
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style>
@font-face {
  font-family: 'Formula1-Regular';
  src: url('@/assets/fonts/Formula1-Regular.otf') format('opentype'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Formula1-Bold';
  src: url('@/assets/fonts/Formula1-Bold.otf') format('opentype'); /* IE9 Compat Modes */
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.dropbtn {
  margin-top: -5px;
  background-color: #FF0000;
  color: white;
  padding: 12px;
  font-size: 12px;
  border-radius: 10px;
  border: none;
}

.dropdown {
  font-family: 'Formula1-Regular', sans-serif;
  font-size: 12px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  margin-left: -63px;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #D1D1D1; /* Add this line for the bottom border */
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #b10000;}
</style>
